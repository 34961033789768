import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Calendaris from '../components/admin/Calendaris.vue'
import Reserves from '../components/admin/Reserves.vue'
import ReservesSocis from '../components/socis/ReservesSocis.vue'
import Usuaris from '../components/admin/Usuaris.vue'
import Login from '../components/admin/Login.vue'
import Registrat from '../components/socis/Registrat.vue'
import MevesReserves from '../components/socis/MevesReserves.vue'
import MevesDades from '../components/socis/MevesDades.vue'
import Encuesta from '../components/socis/Encuesta'
import RecomEncuesta from '../components/admin/RecomEncuesta.vue'
import ForgetPassword from '../components/socis/ForgetPassword.vue'
import Estadistiques from '../components/admin/Estadistiques.vue'

//Ara les que permeten la gestio de les Activitats Dirigides

import ReservesActDirTmp from '../components/admin/ReservesActDirTmp.vue'
import ReservesAdminActDir from '../components/admin/ReservesAdminActDir.vue'
import ReservesSocisActDir from '../components/socis/ReservesSocisActDir.vue'
import CalendarisActDir from '../components/admin/CalendarisActDir.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      libre:true
    }
  },
  {
    path:'/admin/calendaris',
    name:'Calendaris',
    component:Calendaris,
    meta:{
      admin:true,     
    }
  },
  {
    path:'/admin/calendarisactdir',
    name:'CalendarisActDir',
    component:CalendarisActDir,
    meta:{
      admin:true,     
    }
  },
  {
    path:'/admin/reserves',
    name:'Reserves',
    component:Reserves,
    meta:{
      admin:true,
      soci:true

    }
  },
  {
    path:'/admin/reservesadminactdir',
    name:'ReservesAdminActDir',
    component:ReservesAdminActDir,
    meta:{
      admin:true,
      soci:true

    }
  },
  {
    path:'/admin/reservessocisactdir',
    name:'ReservesSocisActDir',
    component:ReservesSocisActDir,
    meta:{
      admin:true,
      soci:true

    }
  },
  {
    path:'/socis/reserves',
    name:'ReservesSocis',
    component:ReservesSocis,
    meta:{
      admin:true,
      soci:true

    }
  },
  {
    path:'/socis/reservatmp',
    name:'ReservesActDirTmp',
    component:ReservesActDirTmp,
    meta:{
      admin:true,
      soci:false

    }
  },
  {
    path:'/admin/usuaris',
    name:'Usuaris',
    component:Usuaris,
    meta:{
      admin:true,    
    }
  },
  {    
  path:'/admin/charts',
  name:'Estadistiques',
  component:Estadistiques,
  meta:{
    admin:true,    
  }
},
  {
    path:'/login',
    name:'Login',
    component:Login,
    meta:{
      libre:true
    }
  },
  {
    path:'/registrat',
    name:'Registrat',
    component:Registrat,
    meta:{
      libre:true
    }
  },
  {
  path:'/socis/mevesreserves',
  name:'MevesReserves',
  component:MevesReserves,
  meta:{
    soci:true,
    admin:true
  }
},
{ 
path:'/socis/mevesdades',
name:'MevesDades',
component:MevesDades,
meta:{
  soci:true,
  admin:true
  }
},
// { 
//   path:'/socis/enquesta',
//   name:'Encuesta',
//   component:Encuesta,
//   meta:{
//     soci:true,
//     admin:true
//     }
//   },
  { 
    path:'/admin/recom_enquesta',
    name:'RecomEncuesta',
    component:RecomEncuesta,
    meta:{
      soci:false,
      admin:true
      }
    },
{
  path:'/socis/ForgetPassword',
  name:'ForgetPassword',
  component:ForgetPassword,
  meta:{
    libre:true
  }
}
]

const router = new VueRouter({
  //mode: 'history',
  mode:'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>{
  if (to.matched.some(record => record.meta.libre)){
    next()
  }else if(store.state.usuari && store.state.usuari.rol == 'admin'){
    if(to.matched.some(record => record.meta.admin)){
      next()
    }
  }
  else if(store.state.usuari && store.state.usuari.rol == 'soci'){
    if(to.matched.some(record => record.meta.soci)){
      next()
    }
  }else{
    next({name:'Home'});
  }
})


export default router
