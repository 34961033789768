<template>
    <v-layout align-start>
        <v-flex>                        
            <v-card>
            <v-card-title>Les meves reserves per la Sala Fitness:</v-card-title>
            <v-card-subtitle>Pròximes reserves de {{persona.sourname}}, {{persona.name}}:</v-card-subtitle>            
                <template v-for="(item) in persona.reserves">                        
                    <v-card-text :key="item._id">  
                       {{diesText[parseInt(item.dia)]}} a les {{item.hora_format}}, {{item.data}}
                        <v-icon
                            small
                            class="ml-2"
                            @click="removePersona(item,1)"
                        >
                            mdi-delete
                        </v-icon>
                    </v-card-text>
                </template>
            </v-card>
            <v-card>
            <v-card-title>Les meves reserves per la Sala d'Activitats Dirigides:</v-card-title>
            <v-card-subtitle>Pròximes reserves de {{persona.sourname}}, {{persona.name}}:</v-card-subtitle>            
                <template v-for="(item) in persona.reservesActDir">                        
                    <v-card-text :key="item._id">  
                       {{diesText[parseInt(item.dia)]}} classe de {{item.titol}} amb la/el {{item.monitor}} a les {{item.hora_inici}}, {{item.data}}
                       <v-card-text :key="item._id">  
                       {{diesText[parseInt(item.dia)]}} a les {{item.hora_format}}, {{item.data}}
                        <v-icon
                            small
                            class="ml-2"
                            @click="removePersona(item,2)"
                        >
                            mdi-delete
                        </v-icon>
                    </v-card-text>
                    </v-card-text>
                </template>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            persona:[],
            diesText:["Dilluns","Dimarts","Dimecres","Dijous","Divendres","Dissabte","Diumenge"],               
        };  
    },
    created(){                  
          this.listar()
        },
    computed: {
            
            
    },
    methods:{
        limpiar(){
            var id=null;
            //Si enviem un id , desde Calendari, a les hores nomes hagafem la reserves de aquell calendari.
             
                        
        },
        listar(){                        
            let me = this; 
            let id = this.$store.state.usuari._id
            var header={"Token":this.$store.state.token};    
            var config = {headers:header}                    
            axios.post('persona/query',{'_id':id},config)
                .then(function (response){
                    me.persona = response.data;
                    console.log("sucess")
                }).catch(function(error){
                    console.log(error);
                })              
        },
        removePersona(item,opcio){            
            let me = this
                let header={"Token":this.$store.state.token};              
                let config = {headers:header};  
                if(opcio==1){
                    axios.post('reserva/removePersona',{'_id':item._id, 'persona':this.$store.state.usuari._id},config)
                    .then(function(response){
                        //me.close()
                        //me.limpiar()
                        me.listar(response.data.calendari._id)              
                    })
                    .catch(function(error){
                        console.log(error)
                    })
                }else if(opcio==2){
                    axios.post('reservaactdir/removePersona',{'_id':item._id, 'persona':this.$store.state.usuari._id},config)
                    .then(function(response){
                        //me.close()
                        //me.limpiar()
                        me.listar(response.data.calendari._id)              
                    })
                    .catch(function(error){
                        console.log(error)
                    })
                }
        },
    },
}
</script>
