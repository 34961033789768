<template>
    <v-layout align-start>
        <v-flex>
<v-data-table
      :headers="headers"
      :items="reserves"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{formTitle}}</v-toolbar-title>
          
          <v-btn ml-4 @click="crearPDF()">
            <v-icon>print</v-icon>
          </v-btn>
          <v-divider
            class="mx-4"
            inset
            vertical
          >
          </v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <!--<template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nova
              </v-btn>
            </template>-->
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.aforament"
                        label="aforament actual"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.aforament_maxim"
                        label="aforament màxim"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.monitor"
                        label="monitor"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.hora_inici"
                        label="hora_inici"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.hora_final"
                        label="hora_final"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.titol"
                        label="titol"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.activate"
                        label="activate"
                      ></v-text-field>
                    </v-col>
                    
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Estas segur de voler eliminar aquesta classe del calendari?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--Aqui anira el dialog de afeixir usuaris a una reserva manualment-->
          <v-dialog
            v-model="dialogAdd"
            scrollable
            max-width="300px"
          >
        <v-card>
          <v-card-title>Selecciona l'usuari</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">
            <v-radio-group v-model="radioSoci"
              column
            >
              <div v-for="item in persones" :key="item._id">
                <v-radio name="radioSoci"
                  :label="item.sourname+', '+item.name"
                  :value="item._id"
                ></v-radio>
             </div>   
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogAdd = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="addPersona()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
            <v-row v-show="valida">
              <v-col>
                <template>
                  <v-alert type="error">{{message}}</v-alert>
                </template>     
              </v-col>
            </v-row>
        </v-card>
      </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.opcions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
          <v-icon
            small
            @click="dialogAddOpen(item._id)"
            >
            mdi-account-plus
          </v-icon>    
      </template>
     <template v-slot:item.dia="{ item }">
      {{ diesText[parseInt(item.dia)] }}

      </template>
      <template v-slot:item.persona="{item}">
        <div v-for="it in item.persona" :key="it.id">
            <v-icon
              small             
              @click="removePersona(item._id, it._id)"
            >
            mdi-account-remove
          </v-icon>
          {{ it.name }}, {{it.sourname}}
        </div>      
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="limpiar"
        >
          Resetejar
        </v-btn>
      </template>
    </v-data-table>
    </v-flex>
    </v-layout>
    
</template>
<script>
import axios from "axios"
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

    export default {
        data(){
                return{ 
                        diesText:["Diumenge","Dilluns","Dimarts","Dimecres","Dijous","Divendres","Dissabte"],                                   
                        dialog: false,
                        formTitle:"Reserves",
                        message:'',
                        valida:0,
                        dialogAdd:false,
                        dialogDelete: false,
                        espai:", ",
                        data:"",
                        reserva_id:'',
                        reserves:[],
                        radioSoci:'',
                        persones:[{
                          _id:null,
                          name:null,
                          sourname:null,
                        }],
                        headers: [
                          {text: 'Opcions', value: 'opcions', sortable:false},                          
                          //{text: 'Data', value: 'data', sortable:true},
                          {text: 'Dia', value: 'dia', sortable:true},
                          {text: 'De', value: 'hora_inici', sortable:true},
                          {text: 'Fins a', value: 'hora_final', sortable:true},
                          {text: 'Aforament Actual', value: 'aforament', sortable:false},
                          {text: 'Aforament Màxim', value: 'aforament_maxim', sortable:false},
                          {text: 'Monitor/a', value: 'monitor', sortable:false},
                          {text: 'Classe', value: 'titol', sortable:false},
                          {text: 'Actiu', value: 'activate'},
                          {text: 'Socis', value: 'persona', sortable:false},
                        ],
                        editedIndex: -1,
                        editedItem: {
                          calendari: {
                            week:0,
                            year:2021
                            },
                          dia: 1,
                          hora: 8.5,
                          aforament: 16,
                        },
                        defaultItem: {
                        },
            };
        },
        computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nova Classe' : 'Editar Classe'
    },
        },

        watch: {
            dialog (val) {
            val || this.close()
            },
            dialogDelete (val) {
            val || this.closeDelete()
            },
        },

        created () {
           this.limpiar()        
        },
        methods: {
          crearPDF(){
            var me = this;
            var columns = [
              {title:"Setmana", dataKey:"week"},
              {title:"Dia", dataKey:"dia"},
              {title:"Classe", dataKey:"titol"},
              {title:"Monitor/a", dataKey:"monitor"},
              {title:"De", dataKey:"hora_inici"},
              {title:"Fins a", dataKey:"hora_final"},
              {title:"Aforament", dataKey:"aforament"},
              {title:"Actiu", dataKey:"activate"},
              {title:"Socis", dataKey:"persona"},
            ]
            var rows=[];
            this.reserves.map(function(x){
              var str_socis = [];
              if(x.persona.length>0){
                console.log("Aqui hi han dades");
                  x.persona.map((sx)=>{
                    str_socis += sx.name+", "+sx.sourname+"\n";
                  })
              }
              
              rows.push({
                week:x.calendari.week,
                dia:me.diesText[parseInt(x.dia)],
                hora_inici:x.hora_inici,
                hora_final:x.hora_final,
                monitor:x.monitor,
                titol:x.titol,
                aforament:x.aforament,
                activate:x.activate,
                persona:str_socis,
              });
              me.data = x.data;                         
            });

            const doc = new jsPDF('p','pt');
          doc.autoTable(columns,rows,{
            margin:{top:60},
            addPageContent: function(data){
              doc.setFontSize(12);
              doc.text(me.data, 40, 30);
            }
          })

          doc.save("ReservesActDirigides.pdf");

          },
            limpiar(){
              var id=null;
              if(!this.$route.params._id){
                id = this.ultimCalendari(); //Agafem el ultim calendari i si no exiteix encara el creem per tota la setmana actual.                        
              }else{
                id = this.$route.params._id//Si enviem un id , desde Calendari, a les hores nomes hagafem la reserves de aquell calendari.
              this.listar(id) ; 
              }            
            },
            ultimCalendari(){
              let me = this;
              let header={"Token":this.$store.state.token};
              let config = {headers:header}
              
              axios.post('calendariactdir/add',null,config)
                .then((result)=>{
                  console.log(result.data._id);
                  me.listar(result.data._id);                  
                });                               
            },
            removePersona(id, persona){
              let me = this
              let header={"Token":this.$store.state.token};
              let config = {headers:header};  
              axios.post('reservaactdir/removePersona',{'_id':id, 'persona':persona}, config)
              .then(function(response){
                me.close()
                me.limpiar()
                
              })
              .catch(function(error){
                console.log(error)
              })
            },
            dialogAddOpen(id){
              this.getAllPersonas();
              this.dialogAdd=1;
              this.reserva_id=id;

            },
            addPersona(){         
              var me = this     
              console.log(this.reserva_id);
              console.log(this.radioSoci);
              let header={"Token":this.$store.state.token};
              let config = {headers:header};              
              axios.put('reservaactdir/addPersona',{'_id':this.reserva_id, 'persona':this.radioSoci}, config)
              .then(function(response){
                if(response.data.message){
                  me.valida = 1
                  me.message = response.data.message;
                }else{
                  me.close()
                  me.reserves.find((element)=>{
                    if(element._id == me.reserva_id){
                      element._id = response.data.reserva;
                    }
                  })
                  //me.close()
                  //me.limpiar()
                }                
                                                                            
              })
              .catch(function(error){
                console.log(error)
              })
            },
            getAllPersonas(){
                let me = this;
                let header={"Token":this.$store.state.token};              
                let config = {headers:header};      
                axios.get('persona/listActive',config).then(function (response){
                    me.persones = response.data;
                }).catch(function(error){
                    console.log(error);
                })
            },

            listar(id = null){
              let me = this;
              let header={"Token":this.$store.state.token};              
              //console.log(header);           
              axios.get('reservaactdir/list',{headers:header,params:{'_id':id}})
                .then(function (response){
                  me.reserves = response.data;
                  me.formTitle = response.data[0].data                  
                }).catch(function(error){
                  console.log(error);
              })
              
            },
            initialize () {
            
            },

            editItem (item) {
            this.editedIndex = this.reserves.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
            
            },

            deleteItem (item) {
            this.editedIndex = this.reserves.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
            },

            deleteItemConfirm () {
            //this.remove() Faltaria fer la funcio remove per eliminar reserves Act Dirigides Reals
            this.reserves.splice(this.editedIndex, 1)
            this.closeDelete()
            },

            close() {
            this.dialog = false
            this.valida=0
            this.message=""
            this.reserva_id=""
            this.reserves=[]
            this.dialogAdd=0;
            /*this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })*/
            },

            closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            },

            save () {
              var me = this
              let header={"Token":this.$store.state.token};
              let config = {headers:header};              
               if (this.editedIndex > -1) {//aqui editamos                    
                  Object.assign(this.reserves[this.editedIndex], this.editedItem)
                    axios.put('reservaactdir/update',this.editedItem,config)
                    .then(function(response){ 
                      me.close()                     
                      me.limpiar()
                    }).catch(function(error){
                        console.log(error);
                        me.close()                     
                        me.limpiar()
                    });
                    //Object.assign(this.persones[this.editedIndex], this.editedItem)
                } else {//Aqui guardamos uno nuevo
                    //this.persones.push(this.editedItem)
                    alert("No es poden afeigir reserves noves");
                }
            },
          }
        }
        </script>