<template>
  <div>
    <!-- Verificar si hay resultados de la encuesta -->
    <div v-if="resultadosEncuesta">
      <!-- Mostrar el título y las opciones de la pregunta 1 -->
      <div>
        <h2>{{ opcionesPregunta1[0].titulo }}</h2>
        <div v-for="(opcion, index) in opcionesPregunta1[1].opciones" :key="index">
          <p>{{ opcion.titulo }}: {{ opcion.valor }}</p>
        </div>
      </div>
      <!-- Mostrar el título y las opciones de la pregunta 2 -->
      <div>
        <h2>{{ opcionesPregunta2[0].titulo }}</h2>
        <div v-for="(opcion, index) in opcionesPregunta2[1].opciones" :key="index">
          <p>{{ opcion.titulo }}: {{ opcion.valor }}</p>
        </div>
      </div>
      <!-- Mostrar el título y las opciones de la pregunta 3 -->
      <div>
        <h2>{{ opcionesPregunta3[0].titulo }}</h2>
        <div v-for="(opcion, index) in opcionesPregunta3[1].opciones" :key="index">
          <p>{{ opcion.titulo }}: {{ opcion.valor }}</p>
        </div>
      </div>
    </div>
    <!-- Mostrar un mensaje si no hay resultados de la encuesta -->
    <div v-else>
      <p>No hay resultados de la encuesta disponibles.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      resultadosEncuesta: null,

      opcionesPregunta1:[
        {titulo:'Per adequar la nostra sala de musculació creieu que es necessari poder adquirir noves màquines i/o material.  Confirmeu SI o NO en la casella.'},
        {opciones:[
          {titulo:'Sí', valor:0},
          {titulo:'No', valor:0},
        ]},
      ],
      opcionesPregunta2:[ 
        {titulo:'Quina de les següents, màquines creus que cal adquirir?'},
        {opciones:[
          { titulo: 'Hip Thust', valor: 0, imageSrc: require('../../assets/images/pre1_0.jpg') },
          { titulo: 'Màquina obertura de pit plaques', valor: 0, imageSrc: require('../../assets/images/pre1_1.jpg') },
          { titulo: 'Màquina glutis i/o abductors/adductors', valor: 0, imageSrc: require('../../assets/images/pre1_2.jpg') }
        ]}
      ],     
      opcionesPregunta3: [
      {titulo:'Per poder incorporar aquest nou equipament, quina màquina creieu que caldria retirar pel seu poc ús o desgast.'},
        {opciones:[
          { titulo: 'Màquina obertura', valor: 0, imageSrc: require('../../assets/images/pre2_0.jpg') },
          { titulo: 'Màquina abductors/adductors', valor: 0, imageSrc: require('../../assets/images/pre2_1.jpg') },
          { titulo: 'Màquina rem', valor: 0, imageSrc: require('../../assets/images/pre2_2.jpg') }
        ]}
      ],

    };
  },
  created() {
    // Llamar a la función para obtener los resultados de la encuesta cuando se carga el componente
    this.obtenerResultadosEncuesta();
  },
  methods: {
    obtenerResultadosEncuesta() {
      let me = this; 
      let id = me.$store.state.usuari._id
      let header={"Token":me.$store.state.token};    
      let config = {headers:header}   

      axios.get('encuesta/list',config)
        .then(response => {
          console.log(`Respuesta del api = ${response.data}`);
          this.resultadosEncuesta = response.data;
           // Actualizar los valores de valor con los resultados de la encuesta
          const resultados = response.data;
          this.opcionesPregunta1[1].opciones[0].valor = resultados.pregunta1.Si;
          this.opcionesPregunta1[1].opciones[1].valor = resultados.pregunta1.No;

          this.opcionesPregunta2[1].opciones[0].valor = resultados.pregunta2.Opcion1;
          this.opcionesPregunta2[1].opciones[1].valor = resultados.pregunta2.Opcion2;
          this.opcionesPregunta2[1].opciones[2].valor = resultados.pregunta2.Opcion3;

          this.opcionesPregunta3[1].opciones[0].valor = resultados.pregunta3.Opcion1;
          this.opcionesPregunta3[1].opciones[1].valor = resultados.pregunta3.Opcion2;
          this.opcionesPregunta3[1].opciones[2].valor = resultados.pregunta3.Opcion3;
          
        })
        .catch(error => {
          console.error('Error al obtener los resultados de la encuesta:', error);
        });
    }
  }
};
</script>