<style>
.success-background {
  background-color: #d4edda; /* Color verde claro */
}

.error-background {
  background-color: #f8d7da; /* Color rojo claro */
}
</style>

<template>
  <v-container>
    <v-form @submit.prevent="submitForm">
      <!-- Pregunta 1 (True/False) -->
      <v-row>
        <v-col cols="12">
          <v-label class="font-weight-bold">Per adequar la nostra sala de musculació creieu que es necessari poder adquirir noves màquines i/o material.  Confirmeu SI o NO en la casella.</v-label>
          <v-radio-group required v-model="pregunta1">
            <v-radio :label="'Sí'" :value="1" ></v-radio>
            <v-radio :label="'No'" :value="0" ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <!-- Pregunta 2 (Radio buttons) -->
      <v-row>
        <v-col cols="12">
          <v-label class="font-weight-bold">Quina de les següents, màquines creus que cal adquirir?</v-label>
          <v-radio-group required v-model="pregunta2">
            <v-col cols="6" v-for="(opcion, index) in opcionesPregunta2" :key="index">
              <v-row class="opcion-row mb-3">
                <v-col cols="12" md="6">
                  <v-radio :label="opcion.label" :value="opcion.value" ></v-radio>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img :src="opcion.imageSrc" height="100" width="100"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-radio-group>
        </v-col>
      </v-row>

      <!-- Pregunta 3 (Radio buttons) -->
      <v-row>
        <v-col cols="12">
          <v-label class="font-weight-bold">Per poder incorporar aquest nou equipament, quina màquina creieu que caldria retirar pel seu poc ús o desgast.</v-label>
          <v-radio-group required v-model="pregunta3">
            <v-col cols="6" v-for="(opcion, index) in opcionesPregunta3" :key="index">
              <v-row class="opcion-row mt-3">
                <v-col cols="12" md="6">
                  <v-radio :label="opcion.label" :value="opcion.value" ></v-radio>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img :src="opcion.imageSrc" height="100" width="100"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-radio-group>
        </v-col>
      </v-row>

      <!-- Botón de enviar -->
      <v-row>
        <v-col cols="12">
          <v-btn type="submit" color="primary">Enviar</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- Modal para mensajes de éxito o error -->
<!-- Modal para mensajes de éxito o error -->
<v-dialog v-model="modalVisible" max-width="500">
      <v-card>
        <v-card-title :class="{ 'success-background': success, 'error-background': !success }">
          {{ success ? 'Éxit' : 'Error' }}
        </v-card-title>
        <v-card-text class="mt-2">
          {{ modalMessage }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="modalVisible = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      request:[],
      pregunta1: null,
      pregunta2: null,
      pregunta3: null,
      opcionesPregunta2: [
        { label: 'Hip Thust', value: 0, imageSrc: require('../../assets/images/pre1_0.jpg') },
        { label: 'Màquina obertura de pit plaques', value: 1, imageSrc: require('../../assets/images/pre1_1.jpg') },
        { label: 'Màquina glutis i/o abductors/adductors', value: 2, imageSrc: require('../../assets/images/pre1_2.jpg') }
      ],
      opcionesPregunta3: [
        { label: 'Màquina obertura', value: 0, imageSrc: require('../../assets/images/pre2_0.jpg') },
        { label: 'Màquina abductors/adductors', value: 1, imageSrc: require('../../assets/images/pre2_1.jpg') },
        { label: 'Màquina rem', value: 2, imageSrc: require('../../assets/images/pre2_2.jpg') }
      ],
      modalVisible: false,
      success: false,
      modalMessage: ''
    };
  },
  methods: {
    submitForm() {
      //cogemos los datos para el post junto el id del usuario activo
      let me = this; 
      if (!this.pregunta1 && !this.pregunta2 && !this.pregunta3) {
          // Si falta alguno de los campos, establecer el mensaje de error en la variable de datos
          me.modalMessage = 'Si us plau, marqui les opcions.';
          me.modalVisible = true;
          me.success = false;
          
          return; // Detiene el proceso de envío del formulario
        }

      
      let id = me.$store.state.usuari._id
      let header={"Token":me.$store.state.token};    
      let config = {headers:header}   

      const data = {
        pregunta1 : parseInt(me.pregunta1),
        pregunta2 : parseInt(me.pregunta2),
        pregunta3 : parseInt(me.pregunta3),      
        persona:id
      }
      axios.post('encuesta/add',data , config)
        .then(function (response){
          me.request = response.data;
          me.success = true;
          me.modalMessage = me.request.message;
          me.modalVisible = true;
          console.log(`Votacion echa exitosamente = ${me.request.message}`);

        }).catch(function(error){
          me.modalVisible = true;
          me.success = false;
          me.modalMessage = error.response.data.message;
          console.log(error.response.data.message);
        })       

      //hacemos el envio a la api



      
      console.log(data)
      //TODO: enviar a api con axios.
    }
  }
};
</script>

<style scoped>
/* Estilos personalizados si es necesario */
</style>
