<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../../assets/logo_centrat_color.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
    </v-row>
    <template v-if="estaRegistrat()">
      <v-row class="text-center">
        <v-col class="mb-4">
        <h2 class="headline font-weight-bold mb-1 mt-3">
          Ja pots reservar la teva hora:
        </h2>
        </v-col>
      </v-row>
      <v-row jusitfy="start">
          <v-col
            v-for="(item, i) in items"
            :key="i"
          >
            <v-card
              :color="item.color"
              dark
              class="mx-auto"
              max-width="350"
              outlined
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h5"
                    v-text="item.title"
                  ></v-card-title>
    
                  <v-card-subtitle v-text="item.artist"></v-card-subtitle>
    
                  <v-card-actions>
                    <v-btn
                      class="ml-2 mt-5"
                      outlined
                      rounded
                      small
                      :to='item.src'
                    >
                      Fer Reserva
                    </v-btn>
                  </v-card-actions>
                </div>
                  
                <v-avatar
                  class="ma-3"
                  size="125"
                  tile
                >
                  <v-icon x-large>{{item.icon}}</v-icon>
                </v-avatar>
                </div>
              </v-card>
          </v-col>
        </v-row> 
    </template> 
    <template v-if="estaRegistrat()==false">
      <v-row class="text-center">
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Benvinguts!
          </h1>

          <p class="subheading font-weight-regular">
          Des d'aquesta àrea els socis podreu reservar hora al Gimnàs Municipal d'Esports, per poder entrar caldrà que us registreu prèviament.
          <br>Ho podeu fer clicant a l'enllaç següent, o des del menú superior dret, a entrar.<br><br></p>
          <v-btn :to="{name:'Registrat'}">Registra't</v-btn>
          <p>En el cas que ja tinguis accés:</p>
          <v-btn :to="{name:'Login'}">Inicia sessió</v-btn>
        <h2 class="headline font-weight-bold mb-3 mt-3">
          Què és el següent?
        </h2>
      </v-col>
    </v-row>
    </template>
    <template>
    <v-row jusitfy="start">
      <v-col justify="start">
        <h2 v-if="estaRegistrat()" class="headline font-weight-bold mb-3">
          Condicions d'ús:</h2>

        <h2 class="headline font-weight-bold mb-3">1er:</h2>
        
          <p>Cal registrar-se a la plataforma. Una vegada es comprovi per part nostra les teves dades de soci, activarem el teu compte i rebràs un correu electrònic a l'adreça de correu a la adreça que has especificat al registre.</p>
          <p>Ja podràs autentificar-te clicant a sobre l'opció entrar a la barra superior dreta.
            Des d'aquest espai veuràs les opcions per fer:                    
            <ul>
              <li>Noves reserves:</li>
              <li>Veure les teves reserves</li>
              <li>Comprovar les teves dades</li>
            </ul>                    
            Amb el teu correu i paraula de pas ja podràs autentificar-te. En cas que no siguis soci del gimnàs  per poder accedir al gimnàs o sessió 
            dirigida hauràs  de pagar prèviament  l’entrada puntual del servei a recepció i poder activar aquell dia el teu registre a 
            la plataforma.
            </p>                   
        <h2>2on:</h2>
        <h3>Reserves per la sala de fitness</h3>
        <p>Podràs veure el calendari amb intervals de 30 minuts, des de les 08:30h fins a les 20:30h de dilluns a divendres i de 09:00h a
          13:00h els dissabtes. Podràs fer la teva reserva de 30m, 1h o 1h i 30m com a màxim per dia en la Sala Gimnàs.</p>          

          <p>Cada casella habilitada correspondrà al temps de reserva que hauràs escollit que serà de  30 minuts. Les  que estiguin desactivades, serà perquè o bé ja ha passat l’horari, o bé ja no queda aforament.</p>
          
          <p>Perquè una reserva es pugui fer haurà de complir el següent:</p>
          <p>
            <ul>
              <li>El calendari és setmanal, només es podran fer les reserves de la setmana en vigor, a excepció de divendres que l’usuari podrà accedir al calendari de la propera setmana.</li>
              <li>L'aforament de la sala és de 18 persones com a màxim, una vegada arribat a aquest nombre l'hora quedarà tancada.</li>
              <li>Només es pot fer una reserva per dia a la sala de fitness i durant la setmana en vigor, de dilluns a divendres.</li>
              <li>Es podrà cancel·lar la reserva si cliques a sobre de la reserva feta, amb l'opció alliberar, però només estarà disponible aquesta opció si encara l'hora no ha transcorregut.</li>
              <li>Reservar hora i no presentar-se, té una sanció, si arribem a les tres sancions es bloquejarà a l'usuari, i caldrà anar a demanar l'alta de nou al monitor/a de la sala.</li>            
            </ul>
          </p>
          <h3>Reserves per les sessions dirigides</h3>
          <p>
            Podràs fer la reserva d’aquelles classes que a la plataforma estiguin activades i variarà depenent de la situació actual de la
             pandèmia.  Veuràs el calendari de classes de la setmana en vigor, de dilluns a divendres.              
            La casella que estigui desactivada, serà perquè, o bé no  cal fer reserva, o bé ja ha passat l’hora o no queda aforament. 
          </p>
          <p>Perquè una reserva es pugui fer haurà de complir el següent:
            <ul>
              <li>El calendari serà setmanal, només es podran fer les reserves de la setmana en vigor, a excepció de divendres que l’usuari podrà accedir al calendari de sessions  de la propera setmana.</li>
              <li>L’aforament serà en la majoria de classes de 16 persones com a màxim, a excepció del jumping i crossfuncional que es reduirà depenent de la situació i normativa vigent.</li>
              <li>Es podrà fer més d’una reserva de classe  al dia i durant la setmana en vigor.</li>
              <li>Per  cancel·lar  la reserva, s’haurà de clicar a sobre la casella de la classe corresponent, amb l’opció alliberar, però només estarà disponible si encara l’hora  no ha transcorregut.</li>
              <li>Reservar hora i no presentar-se, tindrà una sanció, si arribem a tres sancions es bloquejarà l'usuari, i caldrà anar a demanar l’alta de nou a recepció o al monitor/a de la sala.</li>              
            </ul>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2 class="headline font-weight-bold mb-3">
            Enllaços d'interès:
          </h2>        
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-col>
      </v-row> 
    </template>

    <v-dialog v-model="modalOpen" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Ver Modal</v-btn>
      </template>
      <v-card>
        <v-card-title>Missatge:</v-card-title>
        <v-card-text>
          <span v-html="notificationText"></span>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="goToSurvey">Fer enquesta!</v-btn>
          <v-btn @click="closeModal">Tancar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import axios from 'axios'
import Login from './Login.vue'
  export default {
  components: { Login },
    name: 'HelloWorld',

    data: () => ({      
      importantLinks: [
        {
          text: 'Web Municipal',
          href: 'https://www.svc.cat',
        },
        {
          text: "Pavelló Municipal d'esports",
          href: 'https://www.svc.cat/serveis/esports/gimnas-municipal/',
        },        
      ],
      items: [
      {
        color: 'primary_alpha',
        src:{name: 'ReservesSocis'},
        title: 'Sala 1',
        artist: 'Gimnàs',
        icon:"fitness_center"
      },
      {
        color: 'secondary_alpha',
        src: {name: 'ReservesSocisActDir'},
        title: "Sala 2",
        artist: 'Classes Dirigides',
        icon:"sports_gymnastics"
      },
    ],
      modalOpen: false,
      hasVoted: false,
      notificationText: `<b>Encara no has votat?</b></br> Ajuda'ns a decidir la pròxima inversió de material al gimnàs!`,
      estaRegi:false,

    }),
    created(){
      
      this.estaRegi =  this.estaRegistrat();  
      if(this.estaRegi){
        this.checkVote(this.$store.state.usuari._id);
      }    

    },
    methods:{
      estaRegistrat(){
        if(this.$store.state.usuari){          
          return true
        }else{
          return false
        }
      },
      async checkVote(id) {
        
        try {
            const header={"Token":this.$store.state.token};                
            const config = {headers:header}  
            const data = {
              persona:id,
            }
            const response = await axios.post('/encuesta/havotat',data, config);
            console.log(response);
            this.hasVoted = response.data.hasVoted;
            if (this.hasVoted===false) {              
                this.modalOpen = true;
            }
          } catch (error) {
            console.error('Error al comprobar votación:', error);
          }
      },
      goToSurvey() {
        // Navegar a la ruta de la encuesta usando Vue Router
        this.$router.push({ name: 'Encuesta' });
        this.closeModal();
      },
      closeModal() {
        this.modalOpen = false;
      }
    }

  }
</script>
