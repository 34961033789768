<template>
  <v-layout align-start>
    <v-flex d-none d-sm-flex>
      <v-col>
<!--   <div class="section">
      <v-data-table :headers="headers" :items="reserves" hide-actions item-key="_id" group-key="dia" disable-pagination>

            <template slot="group" slot-scope="props">
              <span class="font-weight-bold">
                DIA: {{props.groupIndex + 1}} - {{props.groupName}}
              </span>
            </template>

            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.dia }}</td>
                <td>{{ props.item.hora }}</td>
                <td>{{ props.item.aforament }}</td>                                                
              </tr>
            </template>

          </v-data-table>        
      </div>-->
      <template>            
        <v-row>          
          <v-col v-for="i in 5" :key="i">
            <span class="font-weight-medium ma-5">{{diesText[i]}}</span>          
              <template v-for="(item, index) in reserves">                        
                <v-row v-if="item.dia==i" :key="index" class="ma-5">               
                  <v-btn style="padding:2px;" v-if="estaReservat(item)"
                    color="success"
                    pa-2
                    block
                    elevation="4"                                      
                    small
                    x-large
                    x-small
                    :disabled=isDisable(item)
                    @click="activarDesactivarMostrar(2,item)"
                    :value=item._id
                  >
                  <span>                
                    {{item.hora_inici}} - {{item.hora_final}}
                    <br>{{item.titol}}
                    <br>({{item.monitor}})
                  </span>
                  </v-btn>                
                  <v-btn style="padding:2px;" v-else
                    color="primary"
                    block
                    pa-2
                    elevation="4"
                    outlined
                    small
                    x-large
                    x-small
                    :disabled=isDisable(item)
                    @click="activarDesactivarMostrar(1,item)"
                    :value=item._id
                  >                  
                    <div>{{item.hora_inici}} - {{item.hora_final}}
                      <br>{{item.titol}}
                      <br>({{item.monitor}})
                    </div>                  
                  </v-btn> 
                </v-row>
            </template>
          </v-col>                          
        </v-row>
        <v-row>
              <v-btn
                :disabled=btn_back
                plain
                class="ma-1"
                color:primary
                @click="limpiar()"
              >Tornar a l'actual</v-btn>
              <v-btn
              :disabled=btn_next
              plain
              class="ma-1"
              color:primary
              :value=btn_next_value
              @click="listar(btn_next_value)"
              >Propera setmana</v-btn>
          </v-row>                   
        <v-dialog v-model="adModal" max-width="350" max-height="auto">
           <v-card>
             <v-card-title class="headline" v-if="adAccio==1">
                 Reservar Cita                                    
              </v-card-title>
              <v-card-title class="headline" v-if="adAccio==2">
                Cancelar Cita                                    
              </v-card-title>
              <v-card-title class="headline" v-if="adAccio==3">
                Voldria
              </v-card-title>
                <v-card-text>
                  Estàs a punt de 
                  <span v-if="adAccio==1">
                    Reservar 
                  </span>
                  <span v-if="adAccio==2">
                    Cancelar
                  </span>                                        
                    hora el {{adName}};
                    <span v-html="hearts"></span>
                   </v-card-text>
                   <v-card-actions>
                    <v-spacer></v-spacer>                    
                      <v-btn @click="activarDesactivarTancar()" color="error" text>
                        Cancelar
                        </v-btn>
                        <v-btn v-if="adAccio==1" @click="addPersona()" color="primary" text>
                          Reservar
                        </v-btn>                        
                        <v-btn v-if="adAccio==2" @click="removePersona()" color="normal" text>
                          Alliberar
                        </v-btn>                
                    </v-card-actions>
                    <v-row v-show="valida">
                      <v-col>
                        <template>
                          <v-alert type="error">{{message}}</v-alert>
                        </template>     
                      </v-col>
                    </v-row>
                  </v-card>
                </v-dialog>             
      </template>      
    </v-col>
    </v-flex>          
    <v-flex d-flex d-sm-none>
      <v-col>
        <template>        
             <v-data-table disable-pagination
                :headers="headers"
                :items="reserves"
                :expanded.sync="expanded"
                item-key="_id"                
                group-by="dia"
                >
                <template v-slot:group.header="{ group, headers, toggle, isOpen }">
                    <td @click="toggle" :colspan="headers.length" >
                        <v-btn  small icon :ref="group" :data-open="isOpen">
                            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                            <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                        
                           {{diesText[parseInt(group)]}}                       
                    </td>
                </template>
                <template v-slot:item="{item}">
                    <td v-if="item.dia!=0">
                        <div class="row">
                          <div class="col">                                
                            <v-btn block  v-if="estaReservat(item)" 
                              color="success"
                              elevation="2"                    
                              large
                              :disabled=isDisable(item)
                              @click="activarDesactivarMostrar(2,item)"
                              :value=item._id
                            >
                            <span>{{item.hora_inici}} - {{item.hora_final}}
                            <b>{{item.titol}}</b>
                            {{item.monitor}}</span>
                          </v-btn>
                        
                          <v-btn block v-else
                            color="primary"
                            elevation="2"
                            outlined                            
                            large                            
                            :disabled=isDisable(item)
                            @click="activarDesactivarMostrar(1,item)"
                            :value=item._id
                          >
                            <span>{{item.hora_inici}} - {{item.hora_final}}
                            <b>{{item.titol}}</b>
                            {{item.monitor}}</span>
                        </v-btn>                                       
                      </div>
                    </div>
                  </td>
                </template>
              </v-data-table>
                <template>
                  <div class="row">
                    <div class="col"> 
                      <v-btn
                      :disabled=btn_back                      
                      plain
                      class="ma-1"
                      color:primary
                      @click="limpiar()"
                      >Tornar a l'actual</v-btn>
                      <v-btn
                        :disabled=btn_next                        
                        plain
                        color:primary
                        :value=btn_next_value
                        @click="listar(btn_next_value)"
                      >Propera setmana</v-btn>
                    </div>
                  </div>
                </template>
      </template>
      </v-col>
    </v-flex>          
  </v-layout>    
</template>

<script>
import axios from "axios"
    export default {
        data(){
                return{ 
                        diesText:["Diumenge","Dilluns","Dimarts","Dimecres","Dijous","Divendres","Dissabte"],   
                        groupSortDescending: false,                               
                        dialog: false,
                        btn_next:true,
                        btn_next_value:null,
                        btn_back:false,  
                        expanded: [],
                        togglers: {},
                        adName:"",
                        hearts:String,
                        activa:0,
                        adModal:0,
                        adAccio:0,            
                        adId:'',
                        message:'',
                        valida:0,
                        dialogAdd:false,
                        dialogDelete: false,
                        espai:", ",
                        reserva_id:'',
                        dies:7,
                        reserves:[],
                        radioSoci:'',
                        persones:[{
                          _id:null,
                          name:null,
                          sourname:null,
                        }],
                        headers: [                          
                          {text: 'hora', value: 'hora'},                                                                                                        
                        ],
                        editedIndex: -1,
                        editedItem: {
                          calendari: {
                            week:0,
                            year:2021
                            },
                          dia: 1,
                          hora: 8.5,
                          aforament: 16,
                        },
                        defaultItem: {
                        },
            };
        },
        computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
        },

        watch: {

        },

        created () {
          this.btn_back=false
          this.limpiar()
          this.closeAll()
        },
        methods: {
           limpiar(){
              var id=null;
              this.proximCalendari()
              if(!this.$route.params._id){
                id = this.ultimCalendari(); //Agafem el ultim calendari i si no exiteix encara el creem per tota la setmana actual.                        
              }else{
                id = this.$route.params._id//Si enviem un id , desde Calendari, a les hores nomes hagafem la reserves de aquell calendari.
              this.listar(id) ; 
              }            
            },
            proximCalendari(){
              let day = parseInt(new Date().getDay())
              console.log(typeof(day))
              if(day>=5 || day==0){
                let me = this;
                let header={"Token":this.$store.state.token};              
                let config = {headers:header};  
                axios.post('calendariactdir/addNextWeek',null,config)
                  .then((result)=>{
                    this.btn_next= false;
                    this.btn_next_value = result.data._id;                  
                    //me.listar(result.data._id);                  
                  });
              }else{
               this.btn_next = true; 
               this.btn_next_value = null;
              }   
            },
            activarDesactivarMostrar(accio,item){
              this.adName = this.diesText[item.dia]+", a les: "+ item.hora_inici;
              this.hearts = "<br><br><b>Aforament:</b><br>";
              for(var i=item.aforament_maxim; i>=1;i--){
                if(i>item.aforament){
                  this.hearts +="<span class='mdi mdi-cards-heart'></span>";  
                }else{                                                        
                  this.hearts +="<span class='mdi mdi-cards-heart-outline'></span>";                    
                }                
              }              
              this.hearts +="<br>";
              console.log(this.hearts);
                this.adModal=1;                
                this.adId = item._id;
                if(accio==1){
                    this.adAccio=1;
                }else if(accio==2){
                    this.adAccio=2;                              
                }else{
                    this.adModal=0;
                }
            },            
            activarDesactivarTancar(){
              this.adModal=0;
              this.message="";
              this.close()

            },
            estaReservat(item){
              let me = this
              var response = null

              if(item.persona.length>0){                                
                
                item.persona.forEach( function(element){                  
                  if(element._id===me.$store.state.usuari._id){                                       
                    response = true 
                  }
                });                               
                if(response){
                  return true; // Aqui trobem que hi ha una cita una reserva feta, per tant pintem de verd...
                }else{
                  return false //La reserva no esta feta per la persona logeada, pintem blau disponible...
                }
              }else{
                return false;//Altre cas es que no te cap resevra feta, pintem blau, disponible....
              }
            },
            isDisable(item){
              return (item.aforament == 0 || item.activate==false) ? true : false             
            },
            ultimCalendari(){
              let me = this;
              let header={"Token":this.$store.state.token};              
              let config = {headers:header};  
              axios.post('calendariactdir/add',null,config)
                .then((result)=>{                  
                  me.listar(result.data._id);                  
                });                               
            },
            removePersona(id, persona){
              let me = this
              let header={"Token":this.$store.state.token};              
              let config = {headers:header};  
              axios.post('reservaactdir/removePersona',{'_id':this.adId, 'persona':this.$store.state.usuari._id},config)
              .then(function(response){
                me.close()
                me.listar(response.data.calendari._id);            
              })
              .catch(function(error){
                console.log(error)
              })
            },
            dialogAddOpen(id){
              this.getAllPersonas();
              this.dialogAdd=1;
              this.reserva_id=id;

            },
            addPersona(){         
              let me = this     
              let header={"Token":this.$store.state.token};              
              let config = {headers:header};  
              axios.put('reservaactdir/addPersona',{'_id':this.adId, 'persona':this.$store.state.usuari._id},config)
              .then(function(response){
                if(response.data.message){
                  me.valida = 1
                  me.message = response.data.message
                }else{            
                  me.close();
                  me.listar(response.data.calendari._id);

                }                
                                                                            
              })
              .catch(function(error){
                console.log(error)
              })
            },
            getAllPersonas(){
                let me = this;
                let header={"Token":this.$store.state.token};              
                let config = {headers:header};  
                axios.get('persona/listActive', config).then(function (response){
                    me.persones = response.data;
                }).catch(function(error){
                    console.log(error);
                })
            },

            listar(id = null){
              let me = this;              
              let header={"Token":this.$store.state.token};              
              let config = {headers:header};  
                axios.get('reservaactdir/listCalFormat',{params:{'_id':id}},config)
                .then(function (response){
                  me.reserves = response.data;
                  if(me.btn_next==false){
                    me.btn_back == false 
                  }
                }).catch(function(error){
                  console.log(error);
              })
              
            },
            initialize () {
            
            },

            editItem (item) {
            this.editedIndex = this.reserves.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
            
            },

            deleteItem (item) {
            this.editedIndex = this.reserves.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
            },

            deleteItemConfirm () {
            this.reserves.splice(this.editedIndex, 1)
            this.closeDelete()
            },

            close() {       
            this.adModal=0;
            this.valida=0
            this.message=""
            this.dialogAdd=0;
            /*this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })*/
            },

            closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            },

            save () {
            if (this.editedIndex > -1) {
                Object.assign(this.reserves[this.editedIndex], this.editedItem)
            } else {
                this.reserves.push(this.editedItem)
            }
            this.close()
            },
                toggleAll () {
        Object.keys(this.$refs).forEach(k => {
            //console.log(this.$refs[k])
            this.$refs[k].$el.click()
                })
            },
            toggleAll () {
                    Object.keys(this.$refs).forEach(k => {
                        //console.log(this.$refs[k])
                        this.$refs[k].$el.click()
                    })
                },
                closeAll () {
                    Object.keys(this.$refs).forEach(k => {
                        console.log(this.$refs[k])
                        if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
                            this.$refs[k].$el.click()
                        }
                    })
                },
                openAll () {
                    Object.keys(this.$refs).forEach(k => {
                        if (this.$refs[k] && !this.$refs[k].$attrs['data-open']) {
                            this.$refs[k].$el.click()
                        }
                    })
                }
          }
        }
        </script>