<template>
    <v-layout align-start>
        <v-flex>
            <v-data-table
                :headers="headers"
                :items="calendaris"
                item-key="_id"
                class="elevation-1"                
            >
                <template #item._id="{ value }">
                       <v-btn depressed
                       @click="goToReservas(value)"
                       >
                       Veure Reserves
                       </v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            calendaris:[],            
        }
    },
    created (){
        this.listar()
    },
    methods: {
        goToReservas(id){
            this.$router.push({
                name:'Reserves',
                params:{
                    _id:id
                }
            })
        },
        listar(){
            let me = this;
            let header={"Token":this.$store.state.token};
            let config = {headers:header}
            axios.get('/calendari/listAll',config).then(function (response){
            me.calendaris = response.data;
            }).catch(function(error){
                console.log(error);
        })    
    },
  },
  computed: {
    headers () {
      return [        
        { text:'id', value:'_id'},
        { text: 'Any', value: 'year'}, 
        { text: 'Setmana', value: 'week'},        
        { text: 'Creat en', value: 'createdAt'},        
      ]
    },
  },
}

</script>