<template>
    <v-layout align-center justify-center>
        <v-flex mx12 sm8 lg5 x14>
            <v-card>
                <v-toolbar dark color="#802523">
                <v-toolbar-title>
                    Registra't a la plataforma:
                </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="name" autofocus color="accent" label="Nom" required>
                    </v-text-field>
                    <v-text-field v-model="sourname" autofocus color="accent" label="Cognoms" required>
                    </v-text-field>
                    <v-text-field v-model="mail" autofocus color="accent" label="E-mail" required>
                    </v-text-field>
                    <v-text-field v-model="dni" autofocus color="accent" label="DNI/NIE" required>
                    </v-text-field>                    
                    <v-text-field v-model="password" type="password" color="accent" label="Contrasenya" required>
                    </v-text-field>
                </v-card-text>
                <v-card-actions class="px-3 pb-3">
                    <v-flex text-xs-right>
                        <v-btn @click="enviar()" color="primary">Enviar</v-btn>
                    </v-flex>
                </v-card-actions>
                <v-row v-show="valida2">
                    <v-col>
                        <template>
                            <div class="red--text" v-for="v in validaMsg" :key="v" v-text="v">
                            </div>
                        </template>     
                    </v-col>
                </v-row>
                <v-alert v-show="valida" :type="style">
                    {{message}}
                </v-alert>                           
            </v-card>
        </v-flex>            
    </v-layout>
</template>
<script>
import axios from 'axios';
export default {
    
    data(){
        return{
            name:'',
            sourname:'',
            mail:'',
            dni:'',
            password:'',
            valida:0,
            message:'',
            style:"success",
            valida2:0,
            validaMsg:[],
        }
    },
    methods:{
        validar(){
                this.valida2=0;
                this.validaMsg=[];   
                this.mail = this.mail.toLowerCase()
                this.mail = this.mail.trim()
                if(this.name.length < 1 || this.name.length > 25){
                    this.validaMsg.push('El nom ha de tindre entre 1 i 25 caràcters...');
                }if(this.sourname.length < 1 || this.sourname.length > 50){
                    this.validaMsg.push('El cognom ha de tindre entre 1 i 50 caràcters...');                    
                }if(this.dni.length < 9 || this.dni.length > 9){
                    this.validaMsg.push('El DNI no compleix el estandart.');                    
                }if(this.mail.length < 1 || this.mail.length > 100){
                    this.validaMsg.push('El correu ha de tindre entre 1 i 100 caràcters...');                    
                }if(this.password.length < 8){
                    this.validaMsg.push('La contrasenya ha de tindre mínim 8 caracters...');                                        
                }if(this.validaMsg.length){
                    this.valida2=1;
                }
                return this.valida2;
            },
        enviar(){            
            let me = this
            if(me.validar()==1){                                                          
                return; 
            }            
            let header={"Token":this.$store.state.token};
            let config = {headers:header}; 
  
            axios.post('/persona/add',{name:me.name, sourname:me.sourname, dni:me.dni, mail:me.mail, password:me.password},config)
            .then((response)=>{
                me.valida=1;
                me.message=("S'ha creat correctament el teu usuari, aviat revisarem la teva solicitud i t'avisarem per correu electronic deseguida estigui actiu");
                me.style="success";
            
            })
            .catch((error)=>{
                me.valida=1
                me.message="El correu i/o dni ja estan registrats.";
                me.style="error";
            });
        }
    }
}
</script>