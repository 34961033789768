<template>
    <v-layout align-start>
        <v-flex>
            <v-data-table
                :headers="headers"
                :items="persones"
                :search="search"
                sort-by="sourname"
                class="elevation-1"
                
                >
                <template v-slot:top>
                    <v-toolbar
                        flat
                        >
                        <v-toolbar-title>Usuaris</v-toolbar-title>
                        <v-btn ml-4 @click="crearPDF()">
                            <v-icon>print</v-icon>
                        </v-btn>                        
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" singel-line hide-details></v-text-field>  
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="dialog"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Nou usuari
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                
                                <v-card-text>
                                    <v-container>
                                        <v-row>                           
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.name"
                                                    label="Nom"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.sourname"
                                                    label="Cognoms"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.mail"
                                                    label="Email"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.rol"
                                                    label="Rol"                 
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.dni"
                                                    label="DNI/NIE"                 
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.password"
                                                    label="Contrasenya"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.negatius"
                                                    label="Negatius"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-select
                                                    v-model="editedItem.active"
                                                    label="Actiu"
                                                    :items="estats"                                                                                                            
                                                    dense

                                                ></v-select>
                                            </v-col>                                                                                        
                                        </v-row>                                        
                                        <v-row v-show="valida">
                                            <v-col>
                                                <template>
                                                    <div class="red--text" v-for="v in validaMsg" :key="v" v-text="v">
                                                    </div>
                                                </template>     
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="close"
                                        >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="save"
                                        >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                            <v-card-title class="text-h5">Estàs segur de voler eliminar-ho?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="adModal" max-width="290">
                            <v-card>
                                <v-card-title class="headline" v-if="adAccio==1">
                                    Activar Usuari                                    
                                </v-card-title>
                                <v-card-title class="headline" v-if="adAccio==2">
                                    Desactivar Usuari                                    
                                </v-card-title>
                                <v-card-title class="headline" v-if="adAccio==3">
                                    Eliminar l'usuari          
                                </v-card-title>
                                <v-card-text>
                                    Estàs a punt de 
                                        <span v-if="adAccio==1">
                                            activar 
                                        </span>
                                        <span v-if="adAccio==2">
                                            desactivar
                                        </span>
                                        <span v-if="adAccio==3">
                                            eliminar
                                        </span>
                                        l'usuari = {{adName}};
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                        <v-btn @click="activarDesactivarTancar()" color="error" text="flat">
                                            Cancelar
                                        </v-btn>
                                        <v-btn v-if="adAccio==1" @click="activar()" color="primary" text="flat">
                                            Activar
                                        </v-btn>
                                        <v-btn v-if="adAccio==2" @click="desactivar()" color="normal" text="flat">
                                            Desactivar
                                        </v-btn>
                                        <v-btn v-if="adAccio==3" @click="eliminar()" color="error" text="flat">
                                            Eliminar
                                        </v-btn>
                                </v-card-actions>

                            </v-card>
                        </v-dialog>
                        <!--<v-dialog v-model="detallModal" max-width="1000px">
                            <v-card>
                                <v-card-title>
                                    <v-btn><v-icon>print</v-icon></v-btn>Dades del usuari
                                </v-card-title>
                                <v-card-text> 
                                                                       
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn @click="ocultarDetall()" color="primary" text>Cancelar</v-btn>
                                </v-card-actions>

                            </v-card>
                        </v-dialog>-->
                    </v-toolbar>
                </template>
                <template v-slot:item.action="{item}">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <template v-if="item.active">
                        <v-icon
                            small
                            class="mr-2"
                            @click="activarDesactivarMostrar(2,item)"                                >
                            mdi-block-helper
                        </v-icon>
                    </template>     
                    <template v-else>                                                                                                                                               
                        <v-icon
                            small
                            class="mr-2"
                            @click="activarDesactivarMostrar(1,item)"
                        >
                            mdi-check
                        </v-icon> 
                        <v-icon
                            small
                            class="mr-2"
                            @click="activarDesactivarMostrar(3,item)"
                            >
                            mdi-delete
                        </v-icon>                    
                    </template>
                    <!--<v-template>
                        <v-icon small @click="mostrarDetall()">
                            print
                        </v-icon>
                        
                    </v-template>-->

                </template>
                <template v-slot:item.active="{item}">
                    <div v-if="item.active">
                        <span class="blue--text">Actiu</span>
                    </div>
                    <div v-else>
                        <span class="red--text">Inactiu</span>
                    </div>
                </template>
                <template v-slot:no-data>
                    <v-btn
                    color="primary"
                    @click="listar"
                    >
                    Resetjar
                    </v-btn>
                </template>                                          
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from "axios"
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export default {
    data(){
        return{
            dialog: false,
            detallModal:0,
            dialogDelete: false,
            data:"",
            persones:[],
            search:null,
            valida:0,
            validaMsg:[],
            adModal:0,
            adAccio:0,            
            adId:'',
            adName:"",
            headers: [
            { text: 'Actions', value: 'action', sortable: false },
            { text: 'Nom', value: 'name' },
            { text: 'Cognoms', value: 'sourname', sortable:true },
            { text: 'mail', value: 'mail' },
            { text: 'DNI', value: 'dni' },
            { text: 'Creat en:', value: 'createAt', sortable: true },
            { text: 'Rol/nivell', value:'rol'},
            { text: 'Negatius', value:'negatius'},
            { text: "Actiu", value:"active", sorteable: false}            
            ],
            _id:'',
            estats: [
                {text:"Actiu", value:true},
                {text:"Inactiu", value:false}
            ],
            editedIndex: -1,
            editedItem: {                
                name: "",
                sourname: "",
                mail: "",
                dni:"",                
                rol: "",
                active:true,
                password:"",
                
                
               
            },
            defaultItem: {
                name:"",
                sourname:"",
                mail:"",
                dni:"",                
                rol:'',
                active:true, 
                password:"" 

            },
        };
    },

        computed: {
            formTitle () {
            return this.editedIndex === -1 ? 'Nou Usuari' : 'Editar Usuari'
            },
        },

        watch: {
            dialog (val) {
            val || this.close()
            },
            dialogDelete (val) {
            val || this.closeDelete()
            },
        },

        created () {
            this.listar();
        },

        methods: {
            crearPDF(){
            var me = this;
            var columns = [
              {title:"Nom", dataKey:"name"},
              {title:"Cognoms", dataKey:"sourname"},
              {title:"mail", dataKey:"mail"},
              {title:"DNI", dataKey:"dni"},
              {title:"Creat en:", dataKey:"createAt"},
              {title:"Rol/nivell", dataKey:"rol"},
              {title:"Negatius", dataKey:"negatius"},
              {title:"Actiu", dataKey:"active"},
            ]
            var rows=[];
            this.persones.map(function(x){                                          
              rows.push({
                name:x.name,
                sourname:x.sourname,
                mail:x.mail,
                dni:x.dni,
                createAt:x.createAt.substring(0,10),
                rol:x.rol,
                negatius:x.negatius,
                active:x.active
              });              
            });

            const doc = new jsPDF('p','pt');
          doc.autoTable(columns,rows,{
            margin:{top:60},
            addPageContent: function(data){
              doc.setFontSize(12);
              doc.text("Llistat d'Usuaris", 40, 30);
            }
          })

          doc.save("Usuaris.pdf");

          },
            mostrarDetall(){
                this.detallModal=1;
            },
            ocultarDetall(){
                this.detallModal=0; 
            },
            listar(){
                let me = this;
                let header={"Token":this.$store.state.token};
                let config = {headers:header}
                axios.get('persona/list',config).then(function (response){
                    me.persones = response.data;
                }).catch(function(error){
                    console.log(error);
                })
                },
            initialize () {
     
            },
            activarDesactivarMostrar(accio, item){
                this.adName = item.name +","+ item.sourname;
                this.adModal=1;                
                this.adId = item._id;
                if(accio==1){
                    this.adAccio=1;
                }else if(accio==2){
                    this.adAccio=2;
                }else if(accio==3){
                    this.adAccio=3;                
                }else{
                    this.adModal=0;
                }
            },
            activarDesactivarTancar(){
                this.adModal=0;
            },
            activar(){
                let me = this;      
                let header={"Token":this.$store.state.token};
                let config = {headers:header}          
                axios.put('persona/activate',{'_id':this.adId}, config)
                    .then(function(response){
                        me.limpiar();                                                
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            desactivar(){
                let me = this;       
                let header={"Token":this.$store.state.token};
                let config = {headers:header}         
                axios.put('persona/deactivate',{'_id':this.adId}, config)
                    .then(function(response){
                        me.limpiar();                                                
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            eliminar(){
                let me = this;
                let header={"Token":this.$store.state.token};
                let config = {headers:header}                
                axios.delete('persona/remove',{data:{'_id':this.adId},config})
                    .then(function(response){
                        me.limpiar();                                                
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },
            validar(){
                this.valida=0;
                this.validaMsg=[];
                if(this.editedItem.name.length < 1 || this.editedItem.name.length > 25){
                    this.validaMsg.push('El nom ha de tindre entre 1 i 25 caràcters...');
                }if(this.editedItem.sourname.length < 1 || this.editedItem.sourname.length > 50){
                    this.validaMsg.push('El cognom ha de tindre entre 1 i 50 caràcters...');                    
                }if(this.editedItem.dni.length < 9 || this.editedItem.dni.length > 9){
                    this.validaMsg.push('El DNI no compleix el estandart.');                    
                }if(this.editedItem.mail.length < 1 || this.editedItem.mail.length > 100){
                    this.validaMsg.push('El correu ha de tindre entre 1 i 100 caràcters...');                    
                }if(this.editedItem.password.length < 8){
                    this.validaMsg.push('La contrasenya ha de tindre mínim 8 caracters...');                                        
                }if(this.validaMsg.length){
                    this.valida=1;
                }
                return this.valida;
            },

            editItem (item) {
                this._id = item._id;
                this.editedIndex = this.persones.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                this._id = item._id;
                this.editedIndex = this.persones.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
                this.persones.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            limpiar(){
                this._id='';
                this.adModal=0;
                this.adAccio=0;
                this.adName='';
                this.adId='';
                this.close();
                this.listar();
                this.valida=0;                     
                this.validaMsg=[];
                this.editedItem=-1;
            },
            save () {
                let me = this;
                let header={"Token":this.$store.state.token};
                let config = {headers:header}
                if(me.validar()==1){                                                          
                    return; 
                }
                if (this.editedIndex > -1) {//aqui editamos                    
                    axios.put('persona/updateAdmin',this.editedItem,this._id,config)
                    .then(function(response){
                        me.limpiar();                                                
                    }).catch(function(error){
                        console.log(error);
                    });
                    //Object.assign(this.persones[this.editedIndex], this.editedItem)
                } else {//Aqui guardamos uno nuevo
                    //this.persones.push(this.editedItem)
                    axios.post('persona/add',this.editedItem)
                    .then(function(response){
                        me.limpiar();                                                
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }
            },
        },
}
</script>
