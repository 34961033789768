<template>
    <v-layout align-center justify-center>
        <v-flex mx12 sm8 lg5 x14>
            <v-card>
                <v-toolbar dark color="#802523">
                <v-toolbar-title>
                    Accés a les reserves:
                </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="mail" autofocus color="accent" label="E-Mail" required>
                    </v-text-field>
                    <v-text-field v-model="password" type="password" color="accent" label="Contrasenya" required>
                    </v-text-field>
                    <v-flex class="red--text" v-if="errorM">
                        {{errorM}}
                    </v-flex>
                </v-card-text>
                <v-card-actions class="px-3 pb-3">
                    <v-flex text-xs-right>
                        <v-btn  @click="ingresar()" color="primary">Accedir</v-btn>
                    </v-flex>
                    <v-flex text-xs-left align-end>                                            
                        <v-btn text :to="{name:'Registrat'}" color="secondary">Registra't</v-btn>
                        <v-btn text :to="{name:'ForgetPassword'}" color="normal">He oblidat la contrasenya</v-btn>
                    </v-flex>
                </v-card-actions>
                
            </v-card>
        </v-flex>            
    </v-layout>
</template>
<script>
import axios from 'axios';
export default {
    
    data(){
        return{
            mail:'',
            password:'',
            errorM:null
        }
    },
    methods:{
        ingresar(){
            this.mail = this.mail.toLowerCase() 
            this.mail = this.mail.trim()
            
            axios.post('/persona/login',{mail:this.mail, password:this.password})
            .then((response)=>{
                return response.data
            })
            .then((data)=>{
                this.$store.dispatch("guardaToken", data.tokenReturn);
                this.$router.push({name:'Home'}).catch(() => {});
            })
            .catch((error)=>{
                //console.log(error)
                this.errorM=null;
                if(error.response.status==404){
                    this.errorM='No existeix el usuari o les credencials són incorrectes';
                }else{
                    this.errorM='Hi ha un error amb el servidor';
                }
            });
        }
    }
}
</script>
