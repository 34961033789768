<template>
  <v-app id="app">
  <v-navigation-drawer
      v-model="drawer" v-if="registrat"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-if="esAdmin || esSoci">
        <v-list-item :to="{name:'Home'}">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            Inici
          </v-list-item-title>
        </v-list-item>
        </template> 
        <template v-if="esAdmin">
          <v-list-group>
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title>
                  Administracio
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'Usuaris'}">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Usuaris
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>

          <v-list-item :to="{name: 'Calendaris'}">
            <v-list-item-action>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Calendaris del Gimnàs
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>

            <v-list-item :to="{name: 'CalendarisActDir'}">
            <v-list-item-action>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Calendaris Act. Dirigides
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>

          <v-list-item :to="{name: 'Reserves'}">
            <v-list-item-action>
              <v-icon>table_chart</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Reserves Gimnàs
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>

            <v-list-item :to="{name: 'ReservesAdminActDir'}">
            <v-list-item-action>
              <v-icon>table_chart</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Reserves Act. Dirigides
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>
                

          <v-list-item :to="{name: 'ReservesActDirTmp'}">
            <v-list-item-action>
              <v-icon>mdi-tools</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Configuració de les Activitats Dirigides
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>         
          
          <v-list-item :to="{name: 'Estadistiques'}">
            <v-list-item-action>
              <v-icon>poll</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Estadístiques
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>  

            <v-list-item :to="{name: 'RecomEncuesta'}">
            <v-list-item-action>
              <v-icon>info</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Resultats de la Enquesta
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>


          </v-list-group>
        </template>      

        <template v-if="esAdmin || esSoci">
          <v-list-group>
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title>
                  El meu espai
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'MevesDades'}">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Dades Personals
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>

                <v-list-item :to="{name: 'MevesReserves'}">
            <v-list-item-action>
              <v-icon>table_chart</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Les meves reserves
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>

          <v-list-item :to="{name: 'ReservesSocis'}">
            <v-list-item-action>
              <v-icon>fitness_center</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                Reserva Sala Fitness
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>           

          <v-list-item :to="{name: 'ReservesSocisActDir'}">
            <v-list-item-action>
              <v-icon>sports_gymnastics</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                Reserva Sala Activitats Dirigides
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>
          
          <v-list-item :to="{name: 'Estadistiques'}">
            <v-list-item-action>
              <v-icon>poll</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Estadístiques
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>

            <!-- <v-list-item :to="{name: 'Encuesta'}">
            <v-list-item-action>
              <v-icon>info</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                Enquesta
                </v-list-item-title> 
              </v-list-item-content>            
            </v-list-item>      -->
            <v-list-item :to="{name: 'RecomEncuesta'}">
            <v-list-item-action>
              <v-icon>info</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Resultats de l'Enquesta
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>
               

          </v-list-group>
        </template>

      </v-list>
      </v-navigation-drawer>
    
        <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#802523"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <span class="hidden-sm-and-down">Reserves Sala Gimnàs</span>
        
      
      </v-toolbar-title>      
      <v-spacer></v-spacer>
      <v-btn v-if="registrat" icon @click="logout()">        
        <v-icon>logout</v-icon>Sortir     
      </v-btn>
      <v-btn :to="{name:'Login'}" v-else icon>
        <v-icon>apps</v-icon>Entrar

      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
      </v-container>
    </v-main>
    <v-footer height="auto" color="#eeeeee">
      <v-layout justify-center>
        <v-flex text-xs-center>
          <v-card flat title color="#eeeeee" class="white--text">
            <v-card-text class="black--text pt-0" align="center">            
            <v-img
            :src="require('./assets/logo_footer.png')"
            
            contain
            height="50"
            />            
            © 2022 Copyright: Ajuntament de Sant Vicenç de Castellet &copy;2022
          </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      drawer:true
    }
  },
  computed:{
    registrat(){
      return this.$store.state.usuari
    },
    esAdmin(){
      return this.$store.state.usuari && this.$store.state.usuari.rol == 'admin';
    },
    esSoci(){
    return this.$store.state.usuari && this.$store.state.usuari.rol == 'soci';
    }    
  },
  created(){
    this.$store.dispatch('autoLogin');
  },
  methods:{
    logout(){
      this.$store.dispatch('sortir');
    }

  }
};
</script>
