<template>
    <v-layout align-start>
        <v-flex>
<v-data-table
      :headers="headers"
      :items="reserves"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Plantilla de Reserves per Activitats Dirigides</v-toolbar-title>
          
          <v-btn ml-4 @click="crearPDF()">
            <v-icon>print</v-icon>
          </v-btn>
          <v-divider
            class="mx-4"
            inset
            vertical
          >
          </v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nova
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.dia"
                        label="dia"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.aforament"
                        label="aforament actual"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.aforament_maxim"
                        label="aforament màxim"
                      ></v-text-field>
                    <v-text-field
                        v-model="editedItem.monitor"
                        label="monitor"
                      ></v-text-field>
                    <v-text-field
                        v-model="editedItem.hora_inici"
                        label="hora_inici"
                    ></v-text-field>
                    <v-text-field
                        v-model="editedItem.hora_final"
                        label="hora_final"
                      ></v-text-field>
                    <v-text-field
                        v-model="editedItem.titol"
                        label="titol"
                    ></v-text-field>
                    <v-text-field
                        v-model="editedItem.acivate"
                        label="actiu"
                      ></v-text-field>
                    </v-col>
                    
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--Aqui anira el dialog de afeixir usuaris a una reserva manualment-->

        </v-toolbar>
      </template>
      <template v-slot:item.opcions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>   
      </template>
     <template v-slot:item.dia="{ item }">
      {{ diesText[parseInt(item.dia)] }}

      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="limpiar"
        >
          Resetejar
        </v-btn>
      </template>
    </v-data-table>
    </v-flex>
    </v-layout>
    
</template>
<script>
import axios from "axios"
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

    export default {
        data(){
                return{ 
                        diesText:["Diumenge","Dilluns","Dimarts","Dimecres","Dijous","Divendres","Dissabte"],                                   
                        dialog: false,
                        message:'',
                        valida:0,
                        dialogAdd:false,
                        dialogDelete: false,
                        espai:", ",
                        data:"",
                        reserva_id:'',
                        reserves:[],
                        //radioSoci:'',
                        /*persones:[{
                          _id:null,
                          name:null,
                          sourname:null,
                        }],*/
                        headers: [
                          {text: 'Opcions', value: 'opcions', sortable:false},                                                    
                          {text: 'Dia', value: 'dia', sortable:true},
                          {text: 'Hora inici', value: 'hora_inici', sortable:true},
                          {text: 'Hora final', value: 'hora_final', sortable:true},
                          {text: 'Monitor/ra', value: 'monitor', sortable:true},
                          {text: 'Classe', value:'titol', sotable:true},
                          {text: 'Aforament actual', value: 'aforament', sortable:false},
                          {text: 'Aforament màxim', value: 'aforament_maxim', sortable:false},
                          {text: 'Actiu', value: 'activate'},
                        ],
                        editedIndex: -1,
                        editedItem: {
                          calendari: {
                            week:0,
                            year:2021
                            },
                          dia: 1,
                          hora: 8.5,
                          aforament: 16,
                        },
                        defaultItem: {
                        },
            };
        },
        computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nou registre' : 'Editar registre'
    },
        },

        watch: {
            dialog (val) {
            val || this.close()
            },
            dialogDelete (val) {
            val || this.closeDelete()
            },
        },

        created () {
           this.limpiar() 
           this.listar()       
        },
        methods: {
          crearPDF(){
            var me = this;
            var columns = [
              {title:"Setmana", dataKey:"week"},
              {title:"Dia", dataKey:"dia"},
              {title:"Classe", dataKey:"titol"},
              {title:"Monitor/a", dataKey:"monitor"},
              {title:"Hora inici", dataKey:"hora_inici"},
              {title:"Hora final", dataKey:"hora_final"},
              {title:"Aforament", dataKey:"aforament"},
              {title:"Actiu", dataKey:"activate"},
              {title:"Socis", dataKey:"persona"},
            ]
            var rows=[];
            this.reserves.map(function(x){
              rows.push({
                week:x.calendari.week,
                dia:me.diesText[parseInt(x.dia)],
                hora_inici:x.hora_inici,
                hora_final:x.hora_final,
                titol:x.titol,
                monitor:x.monitor,
                aforament:x.aforament,
                activate:x.activate,
              });
              me.data = x.data;                         
            });

            const doc = new jsPDF('p','pt');
          doc.autoTable(columns,rows,{
            margin:{top:60},
            addPageContent: function(data){
              doc.setFontSize(12);
              doc.text(me.data, 40, 30);
            }
          })

          doc.save("ReservesActDirPlantilla.pdf");

          },
            limpiar(){
              var id=null
              this.reserves=[]                 
              this.listar()
            },

            listar(id = null){
              let me = this;
              let header={"Token":this.$store.state.token};              
              //console.log(header);           
              axios.get('reservaactdirtmp/list',{headers:header,params:{'_id':id}})
                .then(function (response){
                  me.reserves = response.data;                  
                }).catch(function(error){
                  console.log(error);
              })
              
            },
            initialize () {
            
            },

            editItem (item) {
            this.editedIndex = this.reserves.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
            
            },

            deleteItem (item) {
            this.editedIndex = this.reserves.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
            },

            deleteItemConfirm () {
            this.remove()
            this.reserves.splice(this.editedIndex, 1)
            this.closeDelete()
            },

            close() {
            this.dialog = false
            this.valida=0
            this.message=""
            this.reserva_id=""
            //this.reserves=[]
            this.dialogAdd=0;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            },

            closeDelete () {            
            this.dialogDelete = false
            this.$nextTick(() => {                
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            },

            save () {
              var me = this
              let header={"Token":this.$store.state.token}; 
              let config = {headers:header};             
               if (this.editedIndex > -1) {//aqui editamos                    
                  Object.assign(this.reserves[this.editedIndex], this.editedItem)
                  axios.put('reservaactdirtmp/update',this.editedItem,config)
                    .then(function(response){ 
                      me.close()          
                      me.limpiar()           
                      
                    }).catch(function(error){
                        console.log(error);
                        me.close()                     
                        me.limpiar()
                    });
                    //Object.assign(this.persones[this.editedIndex], this.editedItem)
                } else {//Aqui guardamos uno nuevo
                    this.reserves.push(this.editedItem)
                    axios.post('reservaactdirtmp/add',this.editedItem,config)
                      .then(function(response){                        
                        me.close()    
                        me.limpiar()                 
                        
                      }).catch(function(error){
                        alert(error.message);
                      })
                    
                }
            },
            remove(){
              var me = this              
              let header={"Token":this.$store.state.token};                                           
              let config={headers: header}
              console.log(this._id)
                //axios.put('reservaactdirtmp/remove', this.editedItem, config)
                axios.delete('reservaactdirtmp/remove',{data:{'_id':this.editedItem._id},config})
                  .then(function(response){ 
                    me.close()                     
                    me.limpiar()
                  }).catch(function(error){
                    console.log(error);
                    me.close()                     
                    me.limpiar()
                  });
                    //Object.assign(this.persones[this.editedIndex], this.editedItem)                
              }
            }
          }
        </script>