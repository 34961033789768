import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {// es de solo lectura, dos variables token i usuarios
    token:null,
    usuari:null    
  },
  mutations: { // aqui se canvian
    setToken(state,token){
      state.token=token;


    },
    setUsuari(state, usuari){
      state.usuari = usuari;
    }
  },
  actions: {// en vez de mutar relaizan mutacions, operaciones asyncronas
    guardaToken({commit}, token){
      commit("setToken", token)
      commit("setUsuari", decode(token))
      localStorage.setItem("token", token)
      //es desa el token al navegador client

    },
    autoLogin({commit}){
      let token = localStorage.getItem("token");
      if(token){
        commit("setToken", token)
        commit("setUsuari", decode(token))
      }
      router.push({name:'Home'}).catch(() => {});
      // si ja tinc un token valid, no et fara tornar a logar
    },
    sortir({commit}){
      commit("setToken", null);
      commit("setUsuari", null);
      localStorage.removeItem("token");
      router.push({name:'Login'}).catch(() => {});
    },
    tencar({commit}){
      commit("setToken", null);
      commit("setUsuari", null);
      localStorage.removeItem("token");
    }
    
  },
  modules: {
  }
})
