<template>
    <v-layout align-center justify-center>
        <v-flex mx12 sm8 lg5 x14>
            <v-card>
                <v-toolbar dark color="#802523">
                <v-toolbar-title>
                    Has oblidat la contrasenya?
                </v-toolbar-title>
                </v-toolbar>
                <v-card-text>                                                            
                    <v-text-field v-model="mail" autofocus color="accent" label="E-mail" required>
                    </v-text-field>                                        
                </v-card-text>
                <v-card-actions class="px-3 pb-3">
                    <v-flex text-xs-right>
                        <v-btn @click="enviar()" color="primary">Enviar</v-btn>
                    </v-flex>
                </v-card-actions>                     
            </v-card>
            <v-dialog v-model="adModal" max-width="490">
                <v-card>
                    <v-card-title class="headline">
                        Insereix el codi:
                    </v-card-title>                    
                    <v-card-text v-if="pass==1">
                        Comprova el teu correu electronic, s'ha enviat un codi de verificació.
                    </v-card-text>
                    <v-card-text v-if="pass==2">
                        Codi correcte, pots canviar la teva contrasenya:
                    </v-card-text>
                    <template v-if="pass==1">
                        <v-card-text>
                            <v-text-field xs12 sm6 v-model="verifyCode" label="Insertar codi" required color="accent"> 
                            </v-text-field>
                        </v-card-text>                    
                        <v-card-actions class="px-3 pb-3">
                            <v-flex text-xs-right>
                                <v-btn @click="enviarCodi()" color="primary">Enviar</v-btn>
                            </v-flex>
                        </v-card-actions>
                    </template>
                    <template v-if="pass==2">
                        <v-card-text>
                            <v-text-field xs12 sm6 v-model="password" type="password" label="Password" required color="accent"> 
                            </v-text-field>
                            <v-text-field xs12 sm6 v-model="password2" type="password" label="Verificar password" required color="accent"> 
                            </v-text-field>                        
                        </v-card-text>                    
                        <v-card-actions class="px-3 pb-3">
                            <v-flex text-xs-right>
                                <v-btn @click="novaPassword()" color="normal">Enviar</v-btn>
                                <v-btn v-if="final" :to="{name:'login'}" color="primary">Entrar</v-btn>
                            </v-flex>
                        </v-card-actions>
                    </template>
                    <v-alert v-show="valida" :type="style">
                        {{message}}
                    </v-alert>      
                </v-card>
            </v-dialog>
        </v-flex>            
    </v-layout>
</template>
<script>
import axios from 'axios';
export default {
    
    data(){
        return{                        
            mail:'',            
            password:'',
            password2:'',
            _id:'',
            valida:0,
            message:'',
            style:'error',
            final:false,
            verifyCode:'',
            adModal:false,
            pass:'',
            header:null,
            config:null,
        }
    },
    methods:{
        enviar(){
            let me = this            
            axios.post('/persona/forgetpassword',{mail:this.mail, password:this.password})
            .then((response)=>{
                me.valida=1;                
                me.adModal=true;
                me.pass=1;
                me.message=response.data.message;
                me.style="success";
            
            })
            .catch((error)=>{
                me.valida=1
                me.message=error.data.message;
                me.style="error";
            });            
        },
        enviarCodi(){
            let me = this;
            axios.post('/persona/forgetpassword',{mail:me.mail, password:me.password, verifycode:me.verifyCode})
            .then((response)=>{
                me.$store.dispatch("guardaToken", response.data.tokenReturn);
                me._id = me.$store.state.usuari._id;
                me.header={"Token":this.$store.state.token};        
                me.config = {headers:me.header};
                this.$store.dispatch('tencar');
                me.pass=2;
                me.password="";
                me.adModal=true;
                

            })
            .catch((error)=>{
                me.valida=1;
                me.message=error.response.data.message;
                console.log(error);
                me.style="error";
            })         
        },
        novaPassword(){
            let me = this;
            console.log(me._id);
            if(me.password==me.password2 && me.password.length > 8){
                axios.put('persona/update',{_id:me._id, password:me.password},me.config)
                .then((response)=>{
                    me.valida=1;
                    me.message="S'ha canviat la contrasenya correctament.";
                    me.style="success";
                    me.final=true;

                })
                .catch((error)=>{
                    me.valida=1;
                    me.message="Hi ha hagut algun error, contacta amb un administrador.";
                    me.style="error";
                })

            }else{
                me.password="";
                me.password2="";
                me.valida=1;
                me.style="error";
                me.message="Les dues contrasenyes no coincideixen o és mes petita de 8 caràcters...";

            }
        }

    }
}
</script>