<template>
    <v-layout align-start>
        <v-flex>                                                                
            <v-card>
                <v-card-title>Les meves dades:</v-card-title>                
                <v-card-text>Nom: {{persona.name}}</v-card-text>
                <v-card-text>Cognoms: {{persona.sourname}}</v-card-text>
                <v-card-text>DNI/NIE: {{persona.dni}}</v-card-text>
                <v-card-text>E-mail: {{persona.mail}}</v-card-text>
                <v-card-text>Estat: {{persona.active}}</v-card-text>
                <v-card-text>Data d'alta: {{persona.createAt}}</v-card-text>
                <v-flex text-xs-right>
                    <v-btn @click="passreset()" color="primary">Canviar contrasenya</v-btn>
                </v-flex>                                    
            </v-card> 
            <v-dialog v-model="adModal" max-width="490">
                <v-card>
                    <template v-if="pass==1">
                        <v-card-title>Canviar contrasenya:</v-card-title> 
                        <v-card-text>
                            <v-text-field xs12 sm6 v-model="password" type="password" label="Password" required color="accent"> 
                            </v-text-field>
                            <v-text-field xs12 sm6 v-model="password2" type="password" label="Verificar password" required color="accent"> 
                            </v-text-field>                        
                        </v-card-text>                    
                        <v-card-actions class="px-3 pb-3">
                            <v-flex text-xs-right>
                                <v-btn @click="novaPassword()" color="normal">Enviar</v-btn>
                            </v-flex>
                        </v-card-actions>
                    </template>
                    <v-alert v-show="valida" :type="style">
                        {{message}}
                    </v-alert>      
                </v-card>
            </v-dialog>           
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            persona:[],
            valida:null,
            _id:"",
            message:"",
            style:"error",
            password:'',
            password2:'',
            adModal:false,
            pass:"",
            
        };  
    },
    created(){                  
          this.listar()
        },
    computed: {
            
            
    },
    methods:{
        limpiar(){
            var id=null;
            //Si enviem un id , desde Calendari, a les hores nomes hagafem la reserves de aquell calendari.
             
                        
        },
        listar(){                        
            let me = this; 
            let id = this.$store.state.usuari._id
            var header={"Token":this.$store.state.token};    
            var config = {headers:header}                    
            axios.post('persona/query',{'_id':id},config)
                .then(function (response){
                    me.persona = response.data;
                    console.log("sucess")
                }).catch(function(error){
                    console.log(error);
                })              
        },
        passreset(){
            var me = this              
            me.adModal=true;
            me.pass=1;
            me.style="success";
        },
        novaPassword(){
            let me = this
            me._id = me.$store.state.usuari._id;
            me.header={"Token":this.$store.state.token};        
            me.config = {headers:me.header};
            console.log(me._id);
            if(me.password==me.password2 && me.password.length > 8){
                axios.put('persona/update',{_id:me._id, password:me.password},me.config)
                .then((response)=>{
                    me.valida=1;
                    me.message="S'ha canviat la contrasenya correctament.";
                    me.style="success";
                    me.final=true;

                })
                .catch((error)=>{
                    me.valida=1;
                    me.message="Hi ha hagut algun error, contacta amb un administrador.";
                    me.style="error";
                })

            }else{
                me.password="";
                me.password2="";
                me.valida=1;
                me.style="error";
                me.message="Les dues contrasenyes no coincideixen o és mes petita de 8 caràcters...";

            }
        }


    },
}
</script>
